<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="70%"
    top="5vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="190px"
        :model="company"
        :rules="ruleValidate">
        <div class="vm-separate">
          <el-form-item :label="$t('company.name')" prop="name">
            <el-input v-model="company.name" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('company.factoryNo1')" prop="factoryNo1">
            <el-input v-model="company.factoryNo1" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('company.abbreviation')" prop="abbreviation">
            <el-input v-model="company.abbreviation" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('company.brand')" prop="brand">
            <el-input v-model="company.brand" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('company.type')" prop="type">
            <el-select v-model="company.type" :placeholder="$t('common.pleaseSelect')" style="width: 100%">
              <el-option
                v-for="item in companyTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('company.parentId')" prop="parentId">
            <el-input v-model="company.parentName" :placeholder="$t('common.pleaseSelect')" readonly>
              <el-button slot="append" icon="el-icon-search" @click="$refs.selectParent.open()"></el-button>
            </el-input>
            <company-select ref="selectParent" @select="onSelectParent"></company-select>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('company.person')" prop="person">
            <el-input v-model="company.person" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('company.phone')" prop="phone">
            <el-input v-model="company.phone" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('company.unifiedSocialCreditCode')" prop="manufacturerCode">
            <el-input v-model="company.manufacturerCode" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('company.externalNo')" prop="externalNo">
            <el-input v-model="company.externalNo" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div v-if="userName === 'admin'" class="vm-separate">
          <el-form-item :label="$t('company.dedicatedCode')" prop="dedicatedCode">
            <el-input v-model="company.dedicatedCode" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <div></div>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {companyType, companyType_en, typeToArray} from "../../util/constant";
  import CompanySelect from "../company/CompanySelect.vue";
  import auth from "../../util/auth";

  const moduleName = "companies";

  export default {
    components: {
      CompanySelect,
    },
    data() {
      return {
        userName: auth.getUsername(),
        submitLoading: false,
        saveDisabled: false,
        dialogVisible: false,
        contentLoading: false,
        companyTypes: [],
        company: {
          id: 0,
          no: "",
          name: "",
          abbreviation: "",
          externalNo: "",
          manufacturerCode: "",
          brand: "",
          type: 1,
          parentId: 0,
          parentName: "",
          person: "",
          factoryNo1: "",
          phone: "",
          dedicatedCode: 0,
        },
        ruleValidate: {
          name: [
            {required: true, message: this.$t("company.tip.name"), trigger: "blur"},
          ],
        },
      };
    },
    computed: {
      title() {
        return (this.company.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " + this.$t("company.company");
      },
    },
    mounted() {
      this.companyTypes = typeToArray(this.$t("lang") === "en" ? companyType_en : companyType);
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.company.id = id;
        if (id > 0) {
          this.getData();
        }
      },
      getData() {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$api.getById(moduleName, this.company.id).then(res => {
          this.company = Object.assign(this.company, res.data);
          console.log(this.company);
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      onSelectParent(row) {
        this.company.parentId = row.id;
        this.company.parentName = row.name;
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$api.save(moduleName, this.company).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.company.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
      onDialogClose() {
        this.company.parentName = "";
        this.$refs.formValidate.resetFields();
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
