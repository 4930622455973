<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="companies">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('company.type', '企业类型')">
          <el-select v-model.trim="search.type" :placeholder="$t('common.pleaseSelect')" style="width: 100%" clearable>
            <el-option v-for="item in companyTypeList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button v-if="editAuth && platformNoAuth" class="addBtn" @click="$refs.editPage.open(0)">
          {{ $l("elevatorNetApply.add", "+ 新增") }}
        </el-button>
      </template>
      <el-table-column prop="no" :label="$t('company.no')" width="120" align="center"></el-table-column>
      <el-table-column prop="name" :label="$t('company.name')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="elevCount" :label="$t('company.elevCount')" align="center" width="100"></el-table-column>
      <el-table-column prop="userCount" :label="$t('company.userCount')" align="center" width="100"></el-table-column>
      <el-table-column :label="$t('company.type')" :width="$l('company.width', '100')" align="center">
        <template slot-scope="scope">
          {{ getTypeName(scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column v-if="showOrgId" prop="orgName" label="所属单位" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="showOrgId" prop="tenantName" label="租户" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="140" align="center"></el-table-column>
      <el-table-column prop="creator" label="创建人" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="editAuth" :label="$t('common.operation')" width="100" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{ $t("common.delete")
          }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-dialog ref="editPage" @save-success="getList(1)"></edit-dialog>
  </div>
</template>
<script>
import { companyType, companyType_en } from "@/util/constant";
import EditDialog from "./CompanyEdit.vue";
import auth from "@/util/auth";

const moduleName = "companies";

export default {
  components: { EditDialog },
  data() {
    return {
      editAuth: this.$auth(11),
      platformNoAuth: auth.getCurrentOrgId() != 0 || auth.getUsername() === "admin" || auth.getUsername() === "superAdmin",
      showOrgId: auth.getUsername() === "superAdmin" || auth.getTenantAdmin() === "true",
      loading: false,
      userId: 10,
      search: {
        filter: "",
        type: "",
      },
      companyTypeList: [
        { value: 1, label: this.$l("elevatorNetApply.createCom", "制造企业") },
        { value: 2, label: this.$l("elevatorNetApply.maintComName", "维保企业") },
        { value: 3, label: this.$l("elevator.installationEnterprise", "安装企业") },
        { value: 5, label: this.$l("maintWorkOrder.propertyCom", "物业公司") },
        { value: 6, label: this.$l("company.renovationCompany", "改造单位") },
        { value: 8, label: this.$l("elevatorNetApply.useUnit", "使用单位") },
      ],
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.$refs.vmTable.getList(pageNum);
    },
    getTypeName(type) {
      if (this.$i18n.isCn) {
        return companyType[type];
      } else {
        return companyType_en[type];
      }
    },
    handleEdit(row) {
      this.$refs.editPage.open(row.id);
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("company.no") + " " + row.no + "，" + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$api.deleteById(moduleName, row.id).then(res => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
